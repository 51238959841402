import {ErrorHandler, Provider} from '@angular/core';

export class Q9ErrorHandler implements ErrorHandler {
  handleError(error: never): void {
    console.error('An error occurred.\n', error);
  }
}

export function provideErrorHandler(): Provider {
  return {
    provide: ErrorHandler,
    useClass: Q9ErrorHandler
  };
}
