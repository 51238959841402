import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DialogWidth, SessionService, ThemePalette} from '@q9elements/ui-kit/common';
import {ConfirmModalDialogComponent} from '@q9elements/ui-kit/components';
import {catchError, EMPTY, switchMap, throwError} from 'rxjs';

import {Q9maCookieService} from '../services/cookie.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly globalErrors = ['user.err.blocked', 'user.err.deleted_from_system'];
  private readonly localErrors = [
    'organization.not_found',
    'user_in_team.blocked',
    'team.err.is_not_pro',
    'err.rights.enterprise_not_active',
    'team_suspended',
    'team.info.is_not_pro'
  ];
  private readonly permissionErrors = [
    'user.err.org_admin_rights_needed',
    'user.err.user_is_not_sysadmin',
    'release.err.rights.not_release_admin',
    'team.err.pro_trial.expired',
    'user_does_not_have_permission_to_be_map_owner',
    'user_does_not_have_permission_to_be_refmodel_owner'
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private cookieService: Q9maCookieService
  ) {}

  intercept(req: HttpRequest<never>, next: HttpHandler) {
    return next.handle(req).pipe(catchError(error => this.handlerError(error)));
  }

  private handlerError(response: HttpErrorResponse) {
    const {
      error,
      status,
      isForbidden,
      isGlobalError,
      isLocalError,
      isPermissionError,
      isUnauthorized,
      isBadRequest
    } = this.parseErrorResponse(response);

    if (isUnauthorized) {
      return this.logout();
    }

    if (isBadRequest || isPermissionError) {
      return throwError(() => ({error, status}));
    }

    if (isForbidden) {
      this.sessionService.togglePlaceholder(true);

      return this.showErrorDialog(error).pipe(
        switchMap(() => {
          if (isGlobalError) {
            return this.logout();
          }

          if (isLocalError) {
            return this.reload();
          }

          return this.logout({error, status});
        })
      );
    }

    return this.reload();
  }

  private parseErrorResponse(response: HttpErrorResponse) {
    const {error: errorResponse, status: statusCode} = response;
    const {
      error: errorMessage,
      status: errorStatus,
      http_code
    } = errorResponse || {
      error: response.statusText || 'Unhandled error',
      http_code: 401
    };
    const [
      isGlobalError,
      isLocalError,
      isPermissionError,
      isForbidden,
      isUnauthorized,
      isBadRequest
    ] = [
      this.globalErrors.includes(errorStatus),
      this.localErrors.includes(errorStatus),
      this.permissionErrors.includes(errorStatus),
      [statusCode, http_code].some(status => status === 403),
      [statusCode, http_code].some(status => status === 401),
      [statusCode, http_code].some(status => status === 400)
    ];

    return {
      error: errorMessage,
      status: errorStatus,
      isGlobalError,
      isLocalError,
      isPermissionError,
      isForbidden,
      isUnauthorized,
      isBadRequest
    };
  }

  private showErrorDialog(message: string) {
    this.dialog.closeAll();

    return this.dialog
      .open(ConfirmModalDialogComponent, {
        width: DialogWidth.SM,
        data: {
          modalTitle: 'GENERAL.ERROR',
          content: message,
          showCancelButton: false,
          confirmText: 'GENERAL.OK',
          color: ThemePalette.WARN
        }
      })
      .afterClosed();
  }

  private reload() {
    window.location.href = window.location.origin;

    return EMPTY;
  }

  private logout(error?: {error: string; status: number}) {
    this.cookieService.deleteToken();
    const queryParams: {redirect?: string} = {};

    if (window.location.pathname !== '/') {
      queryParams.redirect = encodeURIComponent(window.location.href);
    }

    this.router.navigate(['/signin'], {
      relativeTo: null,
      queryParams
    });

    return error ? throwError(() => error) : EMPTY;
  }
}

export function provideErrorInterceptor() {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  };
}
